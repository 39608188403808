import { Injectable } from '@angular/core';

@Injectable()
export class SessionProvider {
    language:string;
    constructor() {}

    load() {
        return new Promise((resolve, reject) => {
            this.language = sessionStorage.getItem('lang');
            if(!this.language){
                sessionStorage.setItem('lang','sq');
            }
            resolve(true);
        })
    }
}
