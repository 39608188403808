import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SessionProvider } from './providers/session-provider';
// import { ScullyLibModule } from '@scullyio/ng-lib-v8';
// import { ScullyLibModule } from '@scullyio/ng-lib';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
};

export function sessionProviderFactory(session: SessionProvider) {
    return () => session.load();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        NgbModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        PerfectScrollbarModule,
        CommonModule,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        SessionProvider,
        {
            provide: APP_INITIALIZER,
            useFactory: sessionProviderFactory,
            deps: [SessionProvider], multi: true
        },
        Title
    ],
    bootstrap: [AppComponent],

})
export class AppModule { }


